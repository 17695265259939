import * as EmailValidator from 'email-validator'

const validations = {
  validateSame: (x, y) => x === y,
  validateLength: (x, min, max) => x.length >= min && x.length <= max,
  validateNotBlank: (x) => {
    if (x.length === 0) {
      return false
    }

    let idx = x.length
    while (idx--) {
      const curr = x.charAt(idx)
      if (curr !== ' ' && curr !== '\n' && curr !== '\t' && curr !== '\n' && curr !== '\r') {
        return true
      }
    }

    return false
  },

  validateEmail: (x) => EmailValidator.validate(x),
  validateCode: (x) => /^[0-9]{6}$/.test(x) // numerical code, 6 digits long
}

export default validations
