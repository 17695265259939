import { Form, Field } from 'react-final-form'
import * as EmailValidator from 'email-validator'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { screens } from './App'
import { emailParent } from './UserCreationAPI'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { TextField } from '@pokemon/design.ui.input.text-field'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { InputErrorMessage } from './InputErrorMessage'

const onSubmit = (props, t, setLoading) => (values, _, callback) => {
  props.handleFormSubmit(values, async () => {
    setLoading(true)
    const parentEmailSent = await emailParent(values.email)
    if (parentEmailSent === undefined) {
      console.error('unexpected error sending parent email')
      props.setError()
    } else if (parentEmailSent) {
      props.changeScreen(screens.PARENT_REQUIRED_STATE)
    } else {
      props.changeScreen(screens.BAD_EMAIL)
    }
    callback()
    setLoading(false)
  })
}

export const validateEmail = t => (value) => {
  if (!EmailValidator.validate(value)) {
    return t('error_invalid_email', 'Please enter a valid email address')
  }
}

export const ParentEmailForm = (props) => {
  const { t } = useTranslation()
  const { setLoading } = useContext(LoadingContext)
  return <Form
    onSubmit={onSubmit(props, t, setLoading)}
    render={
      ({ handleSubmit, submitting, valid }) => (
        <form onSubmit={handleSubmit}>
          <h1 className = 'header'>{t('parent_header', 'Get a Grown-Up!')}</h1>
          <label className = 'header'
            style={{ marginBottom: '20px' }}>{t('parent_email_label', 'Hello, parent or guardian. You must create an account for your child. Enter your email address below to get started on your child’s account.')}</label>
          <Field
            name="email"
            validate={validateEmail(t)}
          >
            {({ input, meta }) => (
              <React.Fragment>
                 <TextField
                  name="email"
                  placeholder={t('parent_email_placeholder', 'Your Email')}
                  errorText={meta.touched && meta.error && <InputErrorMessage type="invalid">{meta.error}</InputErrorMessage>}
                  {...input}
                />
              </React.Fragment>
            )}
          </Field>
          <BasicButton
            text={t('continue_button', 'Continue')}
            style={{ marginTop: '32px' }}
            type="submit"
            disabled={submitting || !valid}
          />
        </form>
      )
    }
  />
}
