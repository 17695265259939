import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'

import './App.css'

export const ParentEmailSent = (props) => {
  const { t } = useTranslation()
  return <React.Fragment>
    <h1 className = 'header'>{t('parent_email_sent_header', 'Check your inbox')}</h1>
    <div
      style={{ marginBottom: '20px' }}>
      <Trans
        t={t}
        i18nKey='parent_email_sent'
        values={{ email: props.email }}
        defaults="We’ve sent an email to (&lt;strong&gt;{{email}}&lt;/strong&gt;). Follow the instructions in the email to create your child’s account."
        components={{ strong: <strong /> }}
        />
    </div>
    <BasicButton
      type={'link'}
      buttonStatus = 'secondary'
      text={(props.challenge) ? t('return_to_login', 'Return to Login') : t('return_button', 'Return to Pokemon.com')}
      href={props.leaveURL}/>
  </React.Fragment>
}
