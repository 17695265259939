import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'

import './copied-components/borrowed.css'

import spinner from './copied-components/assets/images/pokeball_gray.png'

import './i18n'
import { LoadingProvider } from '@pokemon/design.ui.loading-spinner'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { ThemeProvider, defaultTheme } from '@pokemon/design-v2.themes.mui-theme'
import config from './config'

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.REACT_APP_LAUNCHDARKLY_CLIENT_ID
  })
  ReactDOM.render(
        <LDProvider>
          <ThemeProvider theme={defaultTheme} >
            <React.StrictMode>
                <Suspense fallback={<img className='spinner' src={spinner} alt='…' />}>
                    <LoadingProvider>
                        <App />
                    </LoadingProvider>
                </Suspense>
            </React.StrictMode>
          </ThemeProvider>
        </LDProvider>
        , document.getElementById('root'))
})()

datadogRum.init({
  applicationId: 'abd7fdd0-a605-45bd-abdc-29c765a9bad3',
  clientToken: 'pub4aad27ddeb94c05020cd06029278e0b5',
  site: 'datadoghq.com',
  service: 'account-creation-frontend',
  env: process.env.REACT_APP_STAGE,
  version: require('../package.json').version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackViewsManually: true,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    config.USER_CREATION_URL
    // will add URL once Oryhydra and UPAPI is integration with DataDog
    // `https://access.${process.env.REACT_APP_STAGE}.pokemon.com`,
    // (url) => url.startsWith(`https://me.${process.env.REACT_APP_STAGE}.pokemon.com`),
  ]
})

datadogRum.startSessionReplayRecording()

datadogLogs.init({
  clientToken: 'pub549c790bfd95e830ce8dd5ab0f7f3fff',
  site: 'datadoghq.com',
  silentMultipleInit: true,
  forwardErrorsToLogs: true,
  sampleRate: 100
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
