import { countryCodeReplacementString } from '@tpci/i18next-ext'

const overridesByStage = {
  live: {
    RECAPTCHA_SITE_KEY: '6LdD428fAAAAAONHdz5Ltgi-mOyfN_QUPj9JDb7O',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '619bf56e90dd07171dfb66ca'
  },
  beta2: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '63333bfbb24d1410f8f91ce8',
    PRIVACY_POLICY_URL: `https://beta2.pokemon.com/${countryCodeReplacementString}/api/privacy-policy.json`,
    TERMS_URL: `https://beta2.pokemon.com/${countryCodeReplacementString}/api/terms-of-use.json`
  },
  alpha: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '633337f8f496a411faa39518'
  },
  dev: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '633337f8f496a411faa39518'
  },
  perf: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '6333380c8d2ed91187a4e7f5'
  },
  ciTpci: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '63333c34ace91e11a38e27c6'
  },
  ciTc: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '63333c3fe4b12a11b46b48c8'
  },
  ciNiantic: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '63333c2a4021fd11d93023ad'
  }
}

export function buildConfig (stage, domain) {
  return {
    // Add common config values here
    RECOVERY_URL: `https://recover.${domain}`,
    LOGIN_URL: `https://access.${domain}/login`,
    USER_CREATION_URL: `https://api.join.${domain}`,
    PCOM_URL: `https://www.${domain}`,
    PRIVACY_POLICY_URL: `https://www.${domain}/${countryCodeReplacementString}/api/privacy-policy.json`,
    TERMS_URL: `https://www.${domain}/${countryCodeReplacementString}/api/terms-of-use.json`,
    RECAPTCHA_SITE_KEY: '6LewQm8fAAAAAIVOrJKRe9J12mzHYwhLxldg1-Vx', // always pass
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '633337f8f496a411faa39518',
    ...(overridesByStage[stage] ?? {})
  }
}

export default buildConfig(process.env.REACT_APP_STAGE, process.env.REACT_APP_DOMAIN)
