import React from 'react'
import './App.css'
import AgeGate from './AgeGate'
import Cookies from 'universal-cookie'
import RegistrationForm from './RegistrationForm'
import Complete from './Complete'
import ActivationCodeForm from './ActivationCodeForm'
import { Footer } from './copied-components/template.js'
import { EmailForm } from './EmailForm.js'
import { BadEmail } from './BadEmail.js'
import { ParentEmailForm } from './ParentEmailForm.js'
import { ParentEmailSent } from './ParentEmailSent'
import { loadReCAPTCHA, pokemonDOBFormatter } from './util.js'
import config from './config'
import { withTranslation } from 'react-i18next'
import { datadogRum } from '@datadog/browser-rum'
import { ContentBox } from '@pokemon/design.ui.containers.content-box'
import { Mascots } from '@pokemon/design.ui.containers.background'
import { LoadingContainer, LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { ErrorBoundary } from '@pokemon/design.ui.error.error-boundary'
import { ErrorFallback } from '@pokemon/design.ui.error.error-fallback'
import { resendCode, screennameTaken } from './UserCreationAPI.js'
import { countryCodeReplacementString, getTwoCharacterCountryCodeFromLanguage } from '@tpci/i18next-ext'
import { ModalContextProvider } from '@pokemon/design.ui.containers.base-modal'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { DocumentPage, DocumentType } from '@pokemon/ptc-user.document-page'
import { CreateScreenNamePage } from '@pokemon/ptc-user.create-screen-name-page'

export const screens = Object.freeze({
  AGE_GATE_STATE: 'start',
  ENTER_EMAIL: 'enter email',
  BAD_EMAIL: 'bad email',
  TERMS_OF_USE: 'terms of use',
  PRIVACY_NOTICE: 'privacy notice',
  ENTER_PARENT_EMAIL: 'enter parent email',
  PARENT_REQUIRED_STATE: 'parent email sent',
  SCREEN_FORM_STATE: 'screen form',
  REGISTRATION_FORM_STATE: 'form',
  ACTIVATION_FORM_STATE: 'activation',
  REGISTRATION_COMPLETE: 'complete'
})

class App extends React.Component {
  constructor (props) {
    super(props)

    const queryParams = this.pullQueryParams()
    const ageGateError = this.checkFailedAgeGate()
    const previousSession = this.checkPreviousSession()

    let initialState = screens.AGE_GATE_STATE
    let initialSteps = 8
    if (previousSession.dob && previousSession.country) {
      // if we successfully pulled the date of birth and country from cookies, then we can skip the age gate
      // and go straight to entering the email address (do not pass go, do not collect $200)
      initialState = screens.ENTER_EMAIL
      initialSteps = 7
    }

    if (ageGateError) {
      // underage user should get the parent email screen instead of an error screen
      initialState = screens.ENTER_PARENT_EMAIL
      initialSteps = 2
    }

    if (queryParams.jumpActivate === 'activation') {
      initialState = screens.ACTIVATION_FORM_STATE
      initialSteps = 2
    }

    datadogRum.startView(initialState)

    this.state = {
      clientId: queryParams.clientId,
      redirectUri: queryParams.redirectUri,
      state: queryParams.state,
      event: queryParams.event,
      linkType: queryParams.linkType,
      error: queryParams.error,
      challenge: queryParams.challenge,
      currentScreen: initialState,
      screenStack: [initialState],
      jumpActivate: queryParams.jumpActivate,
      activeStep: 0,
      steps: initialSteps,
      // registration form stuff
      translations: {},
      userValues: {
        date_of_birth: previousSession.dob && pokemonDOBFormatter(previousSession.dob),
        country_code: previousSession.country,
        username: queryParams.username
      }
    }

    this.setError = this.setError.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleSuccessfulCreation = this.handleSuccessfulCreation.bind(this)
    this.handleSuccessfulVerification = this.handleSuccessfulVerification.bind(this)
    this.handleBackButton = this.handleBackButton.bind(this)
    this.handleCloseButton = this.handleCloseButton.bind(this)
  }

  getLeaveURL () { // edited for users exiting from a flow that doesn't have a challenge
    return (this.state.challenge)
      ? `${config.LOGIN_URL}?login_challenge=${this.state.challenge}`
      : config.PCOM_URL
  }

  modifyScreenStack (newScreen, callback, screenStack = undefined, activeStep = this.state.screenStack.length) {
    datadogRum.startView(newScreen)

    this.setState((oldState) => ({
      screenStack: screenStack || [...oldState.screenStack, newScreen],
      currentScreen: newScreen,
      activeStep
    }), callback)
  }

  pushToScreenStack (newScreen, callback) {
    this.modifyScreenStack(newScreen, callback)
  }

  checkFailedAgeGate () {
    const cookies = new Cookies()
    if (cookies.get('failed_age_gate') === 'true') {
      return 'Your parent or guardian can create an account for you.'
    }
  }

  checkPreviousSession () {
    const cookies = new Cookies()
    let dob = cookies.get('dob')
    const country = cookies.get('country')
    if (dob) {
      try {
        dob = new Date(parseInt(dob))
      } catch (e) {
        // if we can't parse it, just pretend it doesn't exist
        dob = undefined
      }
    }

    return {
      country: country,
      dob: dob
    }
  }

  pullQueryParams () {
    const params = new URLSearchParams(window.location.search)
    const jumpActivate = params.get('jump_activate')
    const username = params.get('username')
    const challenge = params.get('challenge')
    // event can be any event that may use QRcodes or independent links (worlds, pokecon, etc.)
    const event = params.get('event')
    // link_type can be Login (null), qrCode, or independentLink
    const linkType = params.get('link_type')
    const error = this.parameterError(params)

    return {
      challenge,
      event,
      linkType,
      jumpActivate,
      username,
      error
    }
  }

  parameterError = (params) => {
    const ourParams = ['challenge', 'lng', 'event', 'link_type', 'username', 'jump_activate']
    for (const key of params.keys()) {
      if (!ourParams.includes(key)) {
        console.error('error invalid parameter')
        return true
      }
    }
    return false
  }

  handleSuccessfulCreation () {
    const cookies = new Cookies()
    cookies.remove('country')
    cookies.remove('dob')
    this.pushToScreenStack(screens.ACTIVATION_FORM_STATE, null)
  }

  handleSuccessfulVerification () {
    this.pushToScreenStack(screens.REGISTRATION_COMPLETE, null)
  }

  setError () {
    this.setState({ error: true })
  }

  changeScreen = (newScreen, callback) => {
    this.pushToScreenStack(newScreen, callback)
  }

  onScreennameCleared = (screenname) => {
    this.setState((oldState) => {
      return {
        userValues: {
          screen_name: screenname,
          ...oldState.userValues
        }
      }
    }, this.changeScreen(screens.REGISTRATION_FORM_STATE))
  }

  handleFormChange (event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    const name = event.target.name

    this.setState({
      [name]: value
    })
  }

  handleFormSubmit = (values, callback) => {
    this.setState((oldState) => {
      return {
        userValues: {
          ...oldState.userValues,
          ...values
        }
      }
    }, callback)
  }

  handleDocumentAccept = (key, nextScreen) => () => {
    this.setState((oldState) => ({
      userValues: {
        ...oldState.userValues,
        [key]: true
      }
    }), () => {
      this.changeScreen(nextScreen)
    })
  }

  handleBackButton = () => {
    const oldStack = this.state.screenStack
    this.modifyScreenStack(oldStack.at(-2), undefined, oldStack.slice(0, oldStack.length - 1), oldStack.length - 2)
  }

  handleCloseButton = () => {
    window.location.href = this.getLeaveURL()
  }

  componentDidMount () {
    loadReCAPTCHA()
    if (this.state.jumpActivate === 'activation') {
      resendCode(this.state.userValues.username).then(result => {
        if (!result) {
          this.setError()
        }
      }).catch(() => {
        this.setError()
      })
    }
    const { ldClient, i18n } = this.props

    ldClient.identify({
      kind: 'user',
      anonymous: true,
      language: i18n.language
    })
  }

  render () {
    const leaveURL = this.getLeaveURL()
    const recoveryURL = `${config.RECOVERY_URL}`
    const { t, i18n, flags } = this.props
    const { isLoading } = this.context
    let showBack = false
    let showClose = false

    const unexpectedError =
      <ErrorFallback
        secondaryButton={{
          text: (this.state.challenge) ? t('return_to_login', 'Return to Log In') : t('return_button', 'Return to Pokemon.com'),
          url: leaveURL
        }}
      />

    const stepProps = flags.club6493StepIndicators
      ? {
          steps: this.state.steps,
          currentStep: this.state.screenStack.length - 1
        }
      : null

    /* use country to determine which links to pass to documentforms. In terms of content,
       we have two sets: the USA version, and the international version translated into various
       languages. So, for the US, we use the US version regardless of language, and for
       international audiences we use the closest language version regardless of country.
    */
    const countryCode = this.state.userValues.country_code
    let termsCode
    if (countryCode === 'US') {
      termsCode = 'us'
    } else {
      termsCode = getTwoCharacterCountryCodeFromLanguage(i18n.language).toLowerCase()
      if (termsCode === 'us') termsCode = 'uk'
    }

    let innerBody
    if (this.state.error) {
      datadogRum.startView({
        name: 'error'
      })

      innerBody = unexpectedError
    } else if (this.state.currentScreen === screens.AGE_GATE_STATE) {
      showClose = true
      innerBody = <AgeGate
                                 setError={this.setError}
                                 changeScreen={this.changeScreen}
                                 handleFormSubmit={this.handleFormSubmit} />
    } else if (this.state.currentScreen === screens.ENTER_EMAIL) {
      showClose = true
      innerBody = <EmailForm country_code={this.state.userValues.country_code} handleFormSubmit={this.handleFormSubmit} changeScreen={this.changeScreen} setError={this.setError}/>
    } else if (this.state.currentScreen === screens.BAD_EMAIL) {
      showBack = true
      showClose = true
      innerBody = <BadEmail leaveURL={leaveURL} recoveryURL={recoveryURL} challenge={this.state.challenge} email={this.state.userValues.email} />
    } else if (this.state.currentScreen === screens.ENTER_PARENT_EMAIL) {
      showClose = true
      innerBody = <ParentEmailForm handleFormSubmit={this.handleFormSubmit} changeScreen={this.changeScreen} setError={this.setError}/>
    } else if (this.state.currentScreen === screens.PARENT_REQUIRED_STATE) {
      showClose = true
      innerBody = <ParentEmailSent email={this.state.userValues.email} leaveURL={leaveURL} challenge={this.state.challenge}/>
    } else if (this.state.currentScreen === screens.TERMS_OF_USE) {
      showBack = true
      showClose = true
      innerBody = <DocumentPage documentType={DocumentType.TermsOfUse}
              documentURL={config.TERMS_URL.replaceAll(countryCodeReplacementString, termsCode)}
              onAccept={this.handleDocumentAccept('terms', screens.PRIVACY_NOTICE)}
              onCancel={(event) => window.location.replace(this.getLeaveURL())}
              setError={(error) => {
                console.error(error)
                this.setError(t('error_terms_of_use', 'An unexpected error occurred while getting the Terms of Use, please try again later.'))
              }}/>
    } else if (this.state.currentScreen === screens.PRIVACY_NOTICE) {
      showBack = true
      showClose = true
      innerBody = <DocumentPage documentType={DocumentType.PrivacyNotice}
              documentURL={config.PRIVACY_POLICY_URL.replaceAll(countryCodeReplacementString, termsCode)}
              onAccept={this.handleDocumentAccept('privacy_notice', this.state.userValues.country_code === 'JP' ? screens.REGISTRATION_FORM_STATE : screens.SCREEN_FORM_STATE)}
              onCancel={(event) => window.location.replace(this.getLeaveURL())}
              setError={(error) => {
                console.error(error)
                this.setError(t('error_privacy_notice', 'An unexpected error occurred while getting the Privacy Notice, please try again later.'))
              }}/>
    } else if (this.state.currentScreen === screens.SCREEN_FORM_STATE) {
      showBack = true
      showClose = true
      innerBody = <CreateScreenNamePage isChild={false}
        checkScreenname={screennameTaken}
        onSkip={() => this.changeScreen(screens.REGISTRATION_FORM_STATE)}
        onScreennameCleared={(screenname) => this.onScreennameCleared(screenname)}
        setError={this.setError}/>
    } else if (this.state.currentScreen === screens.REGISTRATION_FORM_STATE) {
      showBack = true
      showClose = true
      innerBody = <RegistrationForm userValues={this.state.userValues} handleFormSubmit={this.handleFormSubmit} changeScreen={this.changeScreen} setError={this.setError} />
    } else if (this.state.currentScreen === screens.REGISTRATION_COMPLETE) {
      showClose = true
      innerBody = <Complete challenge={this.state.challenge} />
    } else if (this.state.currentScreen === screens.ACTIVATION_FORM_STATE) {
      innerBody = <ActivationCodeForm username={this.state.userValues.username}
                                      changeScreen={this.changeScreen}
                                      setError={this.setError}
                                      email={this.state.userValues.email}
                                      linkType={this.state.linkType}
                                      event={this.state.event} />
    } else {
      datadogRum.startView({
        name: 'error'
      })

      innerBody = unexpectedError
    }

    return (
      <ModalContextProvider>
        <ContentBox onBack={showBack && !isLoading ? this.handleBackButton : null} onClose={showClose ? this.handleCloseButton : null} stepIndicatorProps={stepProps}>
          <div className='App'>
            <div className="body-container">
                <div className="body-wrapper">
                  <ErrorBoundary fallback={unexpectedError}>
                    <LoadingContainer altText={t('pokemon_spinner', 'Spinner')}>
                      {innerBody}
                    </LoadingContainer>
                  </ErrorBoundary>
                </div>
            </div>
          </div>
          <Footer />
        </ContentBox>
        <Mascots />
      </ModalContextProvider>
    )
  }
}
App.contextType = LoadingContext

export default withLDConsumer()(withTranslation()(App))
