import React, { useState, useContext } from 'react'
import { withTranslation, Trans, useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { screens } from './App'
import Validators from './FormValidators'
import { Callout } from '@pokemon/design.ui.containers.callout'
import { InputErrorMessage } from './InputErrorMessage'
import { TextField } from '@pokemon/design.ui.input.text-field'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { activateUser, resendCode } from './UserCreationAPI'

export const ActivationCodeForm = (props) => {
  const [codeResent, setCodeResent] = useState(false)

  const { setLoading } = useContext(LoadingContext)

  const resendCodeButton = async () => {
    setCodeResent(false)
    setLoading(true)
    const codeResent = await resendCode(props.username)
    setLoading(false)
    if (!codeResent) { // if the username is invalid, then something has gone just as wrong as if the API errors out
      console.error('unexpected error resending code')
      props.setError()
    } else {
      setCodeResent(true)
    }
  }

  const onSubmit = async (values, _, callback) => {
    setCodeResent(false)
    setLoading(true)
    const codeVerified = await activateUser(props.username, values.code, props.linkType, props.event)
    setLoading(false)
    if (codeVerified === undefined) {
      console.error('unexpected error submitting code')
      props.setError()
    } else if (codeVerified) {
      props.changeScreen(screens.REGISTRATION_COMPLETE)
    } else {
      callback({ [FORM_ERROR]: t('incorrect_code', 'The code you entered is incorrect. Click "Resend Activation Code" if you need a new code.') })
      return
    }
    callback()
  }

  const { t } = useTranslation()

  return (
      <Form onSubmit={onSubmit} render={
        ({ handleSubmit, form, submitting, hasValidationErrors, hasSubmitErrors, submitError, modifiedSinceLastSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h1 className='header'>{t('activation_header', 'Your code is on the way!')}</h1>
            <label htmlFor="code" style={{ marginBottom: '8px' }}>
              {props.email
                ? <Trans
                  t={t}
                  i18nKey='activation_code_info_email_provided'
                  defaults='We have sent you an activation code to the email you used to create this account (&lt;strong&gt;{{email}}&lt;/strong&gt;). Enter it below within 10 minutes to activate your account. Your account will be inactive until you complete this step.'
                  components={{ strong: <strong /> }}
                  values={{ email: props.email }}
                />
                : t('activation_code_info', 'We have sent you an activation code to the email you used to create this account. Enter it below within 10 minutes to activate your account. Your account will be inactive until you complete this step.')
              }
            </label>
            {codeResent && <Callout variant="success" style={{ marginBottom: '8px', textAlign: 'left' }}>
              {t('code_resent', 'Activation code sent!')}
            </Callout>}
            <Field
              name="code"
              validate={Validators.validateCode(t)}
            >
              {({ input, meta }) => (
                <React.Fragment>
                  <TextField
                    name="activation_code"
                    placeholder={t('activation_code_placeholder', 'Activation Code')}
                    errorText={((meta.error && meta.touched) || (submitError && !modifiedSinceLastSubmit)) && <InputErrorMessage> {meta.error || submitError} </InputErrorMessage>}
                    {...input}
                  />
                </React.Fragment>
              )}
            </Field>
            <button
              className={`resend-code-button ${submitting && 'link-disabled'}`}
              type="button"
              onClick={() => {
                form.restart()
                resendCodeButton()
              }}
              disabled={submitting}
            >
              {t('resend_code', 'Resend Activation Code')}
            </button>
            <BasicButton
              style={{ marginTop: '60px' }}
              text={t('continue_button', 'Continue')}
              type={'submit'}
              disabled={submitting || hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit)}
            />
          </form>
        )
        }
      />
  )
}

export default withTranslation()(ActivationCodeForm)
