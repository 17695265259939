import { checkRecaptcha } from './util'
import Axios from 'axios'
import config from './config'
import i18n from './i18n'

let axiosApi

export const getApi = () => {
  if (axiosApi) return axiosApi
  axiosApi = Axios.create({
    baseURL: config.USER_CREATION_URL,
    timeout: 10000, // millis
    headers: {
      'accept-language': i18n.language
    },
    withCredentials: true
  })
  return axiosApi
}

export const isEmailAvailable = async (email) => {
  return (await validateUserFields({ email }, 'put/validate_user_fields/email'))?.result
}

export const emailParent = async (parentEmail) => {
  try {
    const recaptchaToken = await checkRecaptcha('post/send_parent_email')

    await getApi().post('/send-parent-email', {
      parent_email: parentEmail
    },
    recaptchaToken)

    return true
  } catch (error) {
    if (error.response?.status === 400) {
      return false
    }
  }
}

export const createUser = async (payload) => {
  const recaptchaToken = await checkRecaptcha('post/create_user')

  return await getApi().post('/create-user', payload, { ...recaptchaToken })
}

/**
 * Validates the fields provided using UCAPI, will return both the result and any errors.
 *
 * @param {*} fields The user fields to validate, possible fields:
 * ```js
 * {
 *   "country_code": string,
 *   "date_of_birth": string,
 *   "email": string,
 *   "screen_name": string,
 *   "email_opt_in": boolean,
 *   "email_opt_in_pcenter": boolean,
 *   "terms": boolean,
 *   "privacy_notice": boolean,
 *   "password": string,
 *   "username": string
 * }
 * ```
 * @returns Validation result object, or undefined if the request failed.
 * ```js
 * {
 *   "result": boolean,
 *   "validationErrors": {
 *     field: string[]
 *   }
 * }
 * ```
 */
export const validateUserFields = async (fields, action) => {
  try {
    const recaptchaToken = await checkRecaptcha(action)

    const response = await getApi().put('/validate-user-fields', fields, recaptchaToken)

    return {
      // Response is {} if successfully validated, otherwise object has errors
      result: Object.keys(response.data).length === 0,
      validationErrors: response.data
    }
  } catch (error) {
  }
}

export const screennameTaken = async (screenname) => {
  if (!screenname) {
    return false
  }
  try {
    const recaptchaToken = await checkRecaptcha('put/validate_user_fields/screen_name')

    const response = await getApi().put('/validate-user-fields', {
      screen_name: screenname
    },
    recaptchaToken)
    console.error(response)
    if (response.data.screen_name === undefined) {
      return 'cleared'
    } else {
      return response.data.screen_name[0]
    }
  } catch (error) {
  }
}

export const activateUser = async (username, code, linkType, event) => {
  try {
    const recaptchaToken = await checkRecaptcha('post/activate_user')

    if (linkType || event) {
      // Datadog anylitics here where we record event the user is at and if they used a link or QR code!
    }

    await getApi().post('/activate-user', {
      code,
      username
    },
    recaptchaToken)
    return true
  } catch (error) {
    if (error.response?.status === 404) {
      return false
    }
  }
}

export const resendCode = async (username) => {
  try {
    const recaptchaToken = await checkRecaptcha('post/start_activation')

    await getApi().post('/start-activation', {
      username
    },
    recaptchaToken)
    return true
  } catch (error) {
  }
}
