import React from 'react'
import config from './config'
import { useTranslation } from 'react-i18next'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'

const Complete = props => {
  const { t } = useTranslation()
  let fullUrl = `${config.LOGIN_URL}?login_challenge=${props.challenge}`
  let buttonText = t('return_to_login', 'Return to Login')
  if (!props.challenge) {
    fullUrl = config.PCOM_URL
    buttonText = t('return_button', 'Return to Pokemon.com')
  }

  return (
    <>
      <div className='title-wrapper'>
        <h1>
          {t('success_header', 'Success!')}
        </h1>
        <p className='sub-title'>
          {t('activation_complete', 'Thank you for activating your account!')}
        </p>
      </div>
      <BasicButton
      type={'link'}
      buttonStatus = 'secondary'
      text={buttonText}
      href={fullUrl}
      style={{ marginTop: '10px', width: '100%' }}/>
    </>
  )
}

export default Complete
