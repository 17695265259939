import { load } from 'recaptcha-v3-enterprise'
import config from './config'

export const pokemonDOBFormatter = (date) => {
  return [date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()].map(item => {
    return item.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
  }).join('-')
}

export const getDays = (month, year, enforceLeapYear) => {
  const shortMonths = ['4', '6', '9', '11']

  if (!month || !year) return 31

  if (shortMonths.includes(month)) {
    return 30
  } else if (month === '2') {
    if (enforceLeapYear && !isLeapYear(year)) {
      return 28
    } else {
      return 29
    }
  } else {
    return 31
  }
}

export const isLeapYear = (yearString) => {
  const year = parseInt(yearString, 10)
  const result = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  return result
}

export async function loadReCAPTCHA () {
  return await load(config.RECAPTCHA_SITE_KEY, {
    autoHideBadge: true
  })
}

export const checkRecaptcha = async (attachedReCAPTCHAAction) => {
  const recaptcha = await loadReCAPTCHA()
  if (recaptcha == null) {
    console.error('error loading recaptcha')
    throw new Error()
  }

  const token = await recaptcha.execute(attachedReCAPTCHAAction)
  return {
    headers: {
      'X-RECAPTCHA-TOKEN': token
    }
  }
}
