import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './App.css'
import './BadEmail.css'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'

export const BadEmail = (props) => {
  const { t } = useTranslation()
  return <React.Fragment>
        <h1 className = 'header'>{t('bad_header', 'We are unable to create an account for you.')}</h1>
        <div className='bad-email-message'>
          <Trans
            t={t}
            i18nKey='bad_email'
            defaults="Either an account with this email (&lt;strong&gt;{{email}}&lt;/strong&gt;) already exists or the email is not valid. You can log in with that account or recover your account if you don't remember the log in information."
            values={{ email: props.email }}
            components={{ strong: <strong /> }}
          />
        </div>
      <BasicButton
        type={'link'}
        text= {(props.challenge) ? t('return_to_login', 'Return to Log In') : t('return_button', 'Return to Pokemon.com')}
        href={props.leaveURL}
        buttonStatus={'secondary'}/>
      <BasicButton
        type={'link'}
        text={t('go_to_account_recovery_button', 'Recover My Account')}
        href={props.recoveryURL}
        buttonStatus={'secondary'}/>
    </React.Fragment>
}
