/* eslint jsx-a11y/label-has-associated-control: 1 */
import logo from './assets/images/pokemon_logo.png'
import { useTranslation, Trans } from 'react-i18next'
import React from 'react'

function Header () {
  const { t } = useTranslation()
  return (
    <div>
      <header>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <div className='logo-wrapper'>
          <img className='logo' src={logo} alt={t('pokemon_logo', 'Pokémon')} />
        </div>
      </header>
    </div>
  )
}

function Footer () {
  const { t } = useTranslation()
  const currYear = new Date().getFullYear()
  const googlePrivacyLink = 'https://policies.google.com/privacy'
  const googleTermsLink = 'https://policies.google.com/terms'
  return (
    <div>
      <footer className='footer-wrapper'>
        <span className='copyright'>
          <Trans
            t={t}
            i18nKey='copyright'
            defaults='©{{curr_year}} Pokémon.<br>©1995 - {{curr_year}} Nintendo/Creatures Inc./GAME FREAK inc. TM, ®Nintendo.'
            values={{ curr_year: currYear }}
          />
          </span>
          <span>
          <Trans
              t={t}
              i18nKey="recaptcha_terms_and_privacy"
              defaults="This site is protected by reCAPTCHA and the Google <googlePrivacyPolicyLink>Privacy Policy</googlePrivacyPolicyLink> and <googleTermsLink>Terms of Service</googleTermsLink> apply."
              components={{
                googlePrivacyPolicyLink: <a className='link' href={googlePrivacyLink}></a>,
                googleTermsLink: <a className='link' href={googleTermsLink}></a>
              }}
            />
        </span>
      </footer>
    </div>
  )
}

export { Header, Footer }
