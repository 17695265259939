import { Form, Field } from 'react-final-form'
import * as EmailValidator from 'email-validator'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { screens } from './App'
import { isEmailAvailable } from './UserCreationAPI'
import { BasicCheckbox } from '@pokemon/design.ui.input.basic-checkbox'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { TextField } from '@pokemon/design.ui.input.text-field'
import { InputErrorMessage } from './InputErrorMessage'
import { Callout } from '@pokemon/design.ui.containers.callout'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

const onSubmit = (props, t, setLoading) => (values, _, callback) => {
  values.email_opt_in = values.email_opt_in || false
  values.email_opt_in_pcenter = values.email_opt_in_pcenter || false
  props.handleFormSubmit(values, async () => {
    setLoading(true)
    const isAvailableEmail = await isEmailAvailable(values.email)
    if (isAvailableEmail === undefined) {
      console.error('unexpected error checking email')
      props.setError()
    } else if (isAvailableEmail) {
      props.changeScreen(screens.TERMS_OF_USE)
    } else {
      props.changeScreen(screens.BAD_EMAIL)
    }
    callback()
    setLoading(false)
  })
}

export const validateEmail = t => (value) => {
  if (!EmailValidator.validate(value)) {
    return t('error_invalid_email', 'Please enter a valid email address')
  }
}

const pokemonCenterCountries = ['US', 'UK', 'CA']

export const EmailForm = withLDConsumer()((props) => {
  const { t } = useTranslation()
  const { setLoading } = useContext(LoadingContext)

  /* eslint-disable camelcase */
  const { flags, country_code } = props
  let marketingSection
  if (country_code === 'JP') {
    marketingSection = <></>
  } else if (pokemonCenterCountries.includes(country_code) ||
    (flags.club8019EmailMarketingOptInForAustraliaAndNewZealand && ['AU', 'NZ'].includes(country_code))) {
    marketingSection = pokemonCenterCheckboxes(t)
  } else {
    marketingSection = marketingCheckbox(t)
  }
  /* eslint-enable camelcase */

  return <Form
      onSubmit={onSubmit(props, t, setLoading)}
      validate={values => {
        const errors = {}
        if (values.email?.toLocaleLowerCase() !== values.confirm?.toLocaleLowerCase()) {
          errors.confirm = t('error_matching_email', 'Emails must match')
        }
        return errors
      }}
      render={
          ({ handleSubmit, submitting, valid }) => (
                  <form className="left-aligned" onSubmit={handleSubmit}>
                  <h1 className = 'header'>{t('email_header', 'Enter the email address you will use for this account.')}</h1>
                      <Field
                      name="email"
                      validate={validateEmail(t)}
                  >
                      {({ input, meta }) => (
                          <React.Fragment>
                              <TextField
                                  name="email"
                                  placeholder={t('email_placeholder_one', 'Email')}
                                  errorText={meta.error && meta.touched && <InputErrorMessage type="invalid">{meta.error}</InputErrorMessage>}
                                  {...input} />
                          </React.Fragment>
                      )}
                  </Field>
                  <Field
                      name="confirm"
                      validate={validateEmail(t)}
                  >
                      {({ input, meta }) => (
                          <React.Fragment>
                              <TextField
                                  style={{ marginTop: '10px', marginBottom: '10px' }}
                                  name="emailConfirm"
                                  placeholder={t('email_placeholder_two', 'Confirm Email')}
                                  errorText={meta.error && meta.touched && <InputErrorMessage type="invalid">{meta.error}</InputErrorMessage>}
                                  {...input} />
                          </React.Fragment>
                      )}
                  </Field>
                  <Callout style={{ fontSize: 14 }}>
                      {t('email_type_warning', 'Please do not use an email address that you may lose access to, such as school or work emails.')}
                  </Callout>
                  {marketingSection}
                  <BasicButton
                      text={t('continue_button', 'Continue')}
                      type="submit"
                      disabled={submitting || !valid}
                  />
              </form>
          )
      }
  />
})

const marketingCheckbox = t => (
  <Field
      name="email_opt_in"
      type="checkbox"
  >
      {({ input }) => (
          <BasicCheckbox
              {...input}
              id="marketing"
              type="checkbox"
              label={t('marketing_label', 'I would like to receive marketing communications, including email messages with the latest news, offers, promotions, events, and games, and other ways to receive marketing communications from The Pokemon Company International.')}
          />
      )}
  </Field>
)

const pokemonCenterCheckboxes = t => (
  <React.Fragment>
      <p style={{ paddingTop: '10px', fontSize: '14px' }}>
          {t('email_updates_section', 'I would like to receive email updates from The Pokémon Company International regarding:')}
      </p>
      <Field
          name="email_opt_in"
          type="checkbox"
      >
          {({ input }) => (
              <React.Fragment>
                  <BasicCheckbox
                      {...input}
                      id="marketing"
                      type="checkbox"
                      label={t('email_updates_general', 'News and information about Pokémon')}
                  />
              </React.Fragment>
          )}
      </Field>
      <Field
          name="email_opt_in_pcenter"
          type="checkbox"
      >
          {({ input }) => (
              <React.Fragment>
                  <BasicCheckbox
                      {...input}
                      id="marketing_pcenter"
                      type="checkbox"
                      label={t('email_updates_pcenter', 'News and updates about Pokémon Center (our official online shop)')}
                  />
              </React.Fragment>
          )}
      </Field>
  </React.Fragment>
)
