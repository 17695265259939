import { getDays } from './util'
import validations from './Validations'

const Validators = {
  validateUsername: function (t) {
    return (username) => {
      if (!username || !validations.validateNotBlank(username)) {
        return t('username.required', 'Username is a required field.')
      } else if (!username.match(/^[0-9a-zA-ZÀ-ÿ_+]+$/)) {
        return t('username.characters', 'Username must contain only valid characters.')
      } else if (username.length < 6) {
        return t('username.min_length', 'Username must be more than 6 characters long.')
      } else if (username.length > 16) {
        return t('username.max_length', 'Username must be less than 17 characters long.')
      } else {
        return null
      }
    }
  },

  validateScreenname: function (t) {
    return (screenname) => {
      if (!screenname || !validations.validateNotBlank(screenname)) {
        return t('screenname.required', 'No screen name entered.')
      } else if (screenname.length < 3) {
        return t('screenname.min_length', 'Screen name must be no less than 3 characters long.')
      } else if (screenname.length > 15) {
        return t('screenname.max_length', 'Screen name must be no more than 15 characters long')
      } else if ((screenname.charAt(0) === '-') || (screenname.charAt(screenname.length - 1) === '-')) {
        return t('screenname.dashes', 'Screen name can not begin or end with a dash')
      } else if (!/^[0-9a-zA-ZÀ-ÿ_-]+$/.test(screenname)) {
        return t('screenname.characters', 'Screen name must only contain valid characters')
      } else {
        return null
      }
    }
  },

  validateCode: function (t) {
    return (code) => {
      return validations.validateCode(code) ? null : t('invalid_code', 'The code you entered is invalid.')
    }
  },

  validateBirthDate: (t) => {
    return (datePartsObject) => {
      if (!datePartsObject.year || !datePartsObject.month || !datePartsObject.day) return t('incomplete_date', 'The date you entered is incomplete.')

      if (datePartsObject.day > getDays(datePartsObject.month, datePartsObject.year, true)) {
        return t('invalid_date', 'The date you entered does not exist.')
      }
    }
  }
}

export default Validators
