import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useCountries () {
  const { t } = useTranslation()

  return useMemo(() => {
    const countries = translateCountries(t)
    countries.sort((c1, c2) => {
      const byOrder = c2.order - c1.order
      if (byOrder !== 0) {
        return byOrder
      }
      return c1.label.localeCompare(c2.label)
    })
    return countries
  }, [t])
}

function translateCountries (t) {
  return [
    { value: 'AD', label: t('country_ad', 'Andorra'), order: 0, consent_age: 13 },
    { value: 'AE', label: t('country_ae', 'United Arab Emirates'), order: 0, consent_age: 13 },
    { value: 'AF', label: t('country_af', 'Afghanistan'), order: 0, consent_age: 13 },
    { value: 'AG', label: t('country_ag', 'Antigua and Barbuda'), order: 0, consent_age: 13 },
    { value: 'AI', label: t('country_ai', 'Anguilla'), order: 0, consent_age: 13 },
    { value: 'AL', label: t('country_al', 'Albania'), order: 0, consent_age: 13 },
    { value: 'AM', label: t('country_am', 'Armenia'), order: 0, consent_age: 13 },
    { value: 'AN', label: t('country_an', 'Netherlands Antilles'), order: 0, consent_age: 13 },
    { value: 'AO', label: t('country_ao', 'Angola'), order: 0, consent_age: 13 },
    { value: 'AR', label: t('country_ar', 'Argentina'), order: 0, consent_age: 13 },
    { value: 'AT', label: t('country_at', 'Austria'), order: 0, consent_age: 14 },
    { value: 'AU', label: t('country_au', 'Australia'), order: 0, consent_age: 15 },
    { value: 'AW', label: t('country_aw', 'Aruba'), order: 0, consent_age: 13 },
    { value: 'AZ', label: t('country_az', 'Azerbaijan'), order: 0, consent_age: 13 },
    { value: 'BA', label: t('country_ba', 'Bosnia and Herzegovina'), order: 0, consent_age: 13 },
    { value: 'BB', label: t('country_bb', 'Barbados'), order: 0, consent_age: 13 },
    { value: 'BE', label: t('country_be', 'Belgium'), order: 0, consent_age: 13 },
    { value: 'BF', label: t('country_bf', 'Burkina Faso'), order: 0, consent_age: 13 },
    { value: 'BG', label: t('country_bg', 'Bulgaria'), order: 0, consent_age: 14 },
    { value: 'BH', label: t('country_bh', 'Bahrain'), order: 0, consent_age: 13 },
    { value: 'BI', label: t('country_bi', 'Burundi'), order: 0, consent_age: 13 },
    { value: 'BJ', label: t('country_bj', 'Benin'), order: 0, consent_age: 13 },
    { value: 'BN', label: t('country_bn', 'Brunei'), order: 0, consent_age: 13 },
    { value: 'BO', label: t('country_bo', 'Bolivia'), order: 0, consent_age: 13 },
    { value: 'BR', label: t('country_br', 'Brazil'), order: 0, consent_age: 18 },
    { value: 'BS', label: t('country_bs', 'Bahamas'), order: 0, consent_age: 13 },
    { value: 'BW', label: t('country_bw', 'Botswana'), order: 0, consent_age: 13 },
    { value: 'BY', label: t('country_by', 'Belarus'), order: 0, consent_age: 13 },
    { value: 'BZ', label: t('country_bz', 'Belize'), order: 0, consent_age: 13 },
    { value: 'CA', label: t('country_ca', 'Canada'), order: 1, consent_age: 13 },
    { value: 'CD', label: t('country_cd', 'Congo, Democratic Republic of the'), order: 0, consent_age: 13 },
    { value: 'CF', label: t('country_cf', 'Central African Republic'), order: 0, consent_age: 13 },
    { value: 'CH', label: t('country_ch', 'Switzerland'), order: 0, consent_age: 16 },
    { value: 'CK', label: t('country_ck', 'Cook Islands'), order: 0, consent_age: 13 },
    { value: 'CL', label: t('country_cl', 'Chile'), order: 0, consent_age: 18 },
    { value: 'CM', label: t('country_cm', 'Cameroon'), order: 0, consent_age: 13 },
    { value: 'CN', label: t('country_cn', 'China (PRC)'), order: 0, consent_age: 13 },
    { value: 'CO', label: t('country_co', 'Colombia'), order: 0, consent_age: 13 },
    { value: 'CR', label: t('country_cr', 'Costa Rica'), order: 0, consent_age: 13 },
    { value: 'CS', label: t('country_cs', 'Serbia'), order: 0, consent_age: 13 },
    { value: 'CW', label: t('country_cw', 'Curaçao'), order: 0, consent_age: 13 },
    { value: 'CY', label: t('country_cy', 'Cyprus'), order: 0, consent_age: 14 },
    { value: 'CZ', label: t('country_cz', 'Czech Republic'), order: 0, consent_age: 15 },
    { value: 'DE', label: t('country_de', 'Germany'), order: 0, consent_age: 16 },
    { value: 'DJ', label: t('country_dj', 'Djibouti'), order: 0, consent_age: 13 },
    { value: 'DK', label: t('country_dk', 'Denmark'), order: 0, consent_age: 13 },
    { value: 'DM', label: t('country_dm', 'Dominica'), order: 0, consent_age: 13 },
    { value: 'DO', label: t('country_do', 'Dominican Republic'), order: 0, consent_age: 13 },
    { value: 'DZ', label: t('country_dz', 'Algeria'), order: 0, consent_age: 13 },
    { value: 'EC', label: t('country_ec', 'Ecuador'), order: 0, consent_age: 13 },
    { value: 'EE', label: t('country_ee', 'Estonia'), order: 0, consent_age: 13 },
    { value: 'EG', label: t('country_eg', 'Egypt'), order: 0, consent_age: 13 },
    { value: 'ER', label: t('country_er', 'Eritrea'), order: 0, consent_age: 13 },
    { value: 'ES', label: t('country_es', 'Spain'), order: 0, consent_age: 14 },
    { value: 'ET', label: t('country_et', 'Ethiopia'), order: 0, consent_age: 13 },
    { value: 'FI', label: t('country_fi', 'Finland'), order: 0, consent_age: 13 },
    { value: 'FR', label: t('country_fr', 'France'), order: 0, consent_age: 15 },
    { value: 'GA', label: t('country_ga', 'Gabon'), order: 0, consent_age: 13 },
    { value: 'GB', label: t('country_gb', 'United Kingdom (Great Britain)'), order: 0, consent_age: 13 },
    { value: 'GD', label: t('country_gd', 'Grenada'), order: 0, consent_age: 13 },
    { value: 'GE', label: t('country_ge', 'Georgia'), order: 0, consent_age: 13 },
    { value: 'GF', label: t('country_gf', 'French Guiana'), order: 0, consent_age: 13 },
    { value: 'GG', label: t('country_gg', 'Guernsey'), order: 0, consent_age: 13 },
    { value: 'GH', label: t('country_gh', 'Ghana'), order: 0, consent_age: 13 },
    { value: 'GI', label: t('country_gi', 'Gibraltar'), order: 0, consent_age: 13 },
    { value: 'GM', label: t('country_gm', 'Gambia'), order: 0, consent_age: 13 },
    { value: 'GP', label: t('country_gp', 'Guadeloupe'), order: 0, consent_age: 13 },
    { value: 'GQ', label: t('country_gq', 'Equatorial Guinea'), order: 0, consent_age: 13 },
    { value: 'GR', label: t('country_gr', 'Greece'), order: 0, consent_age: 15 },
    { value: 'GT', label: t('country_gt', 'Guatemala'), order: 0, consent_age: 13 },
    { value: 'GU', label: t('country_gu', 'Guam'), order: 0, consent_age: 13 },
    { value: 'GW', label: t('country_gw', 'Guinea-Bissau'), order: 0, consent_age: 13 },
    { value: 'GY', label: t('country_gy', 'Guyana'), order: 0, consent_age: 13 },
    { value: 'HK', label: t('country_hk', 'Hong Kong'), order: 0, consent_age: 13 },
    { value: 'HN', label: t('country_hn', 'Honduras'), order: 0, consent_age: 13 },
    { value: 'HR', label: t('country_hr', 'Croatia'), order: 0, consent_age: 16 },
    { value: 'HT', label: t('country_ht', 'Haiti'), order: 0, consent_age: 13 },
    { value: 'HU', label: t('country_hu', 'Hungary'), order: 0, consent_age: 16 },
    { value: 'ID', label: t('country_id', 'Indonesia'), order: 0, consent_age: 13 },
    { value: 'IE', label: t('country_ie', 'Ireland'), order: 0, consent_age: 16 },
    { value: 'IL', label: t('country_il', 'Israel'), order: 0, consent_age: 13 },
    { value: 'IM', label: t('country_im', 'Isle of Man'), order: 0, consent_age: 13 },
    { value: 'IN', label: t('country_in', 'India'), order: 0, consent_age: 13 },
    { value: 'IS', label: t('country_is', 'Iceland'), order: 0, consent_age: 13 },
    { value: 'IT', label: t('country_it', 'Italy'), order: 0, consent_age: 14 },
    { value: 'JE', label: t('country_je', 'Jersey'), order: 0, consent_age: 13 },
    { value: 'JM', label: t('country_jm', 'Jamaica'), order: 0, consent_age: 13 },
    { value: 'JO', label: t('country_jo', 'Jordan'), order: 0, consent_age: 13 },
    { value: 'JP', label: t('country_jp', 'Japan'), order: 0, consent_age: 13 },
    { value: 'KE', label: t('country_ke', 'Kenya'), order: 0, consent_age: 13 },
    { value: 'KM', label: t('country_km', 'Comoros'), order: 0, consent_age: 13 },
    { value: 'KN', label: t('country_kn', 'Saint Kitts and Nevis'), order: 0, consent_age: 13 },
    { value: 'KW', label: t('country_kw', 'Kuwait'), order: 0, consent_age: 13 },
    { value: 'KY', label: t('country_ky', 'Cayman Islands'), order: 0, consent_age: 13 },
    { value: 'KZ', label: t('country_kz', 'Kazakhstan'), order: 0, consent_age: 13 },
    { value: 'LB', label: t('country_lb', 'Lebanon'), order: 0, consent_age: 13 },
    { value: 'LC', label: t('country_lc', 'Saint Lucia'), order: 0, consent_age: 13 },
    { value: 'LI', label: t('country_li', 'Liechtenstein'), order: 0, consent_age: 16 },
    { value: 'LS', label: t('country_ls', 'Lesotho'), order: 0, consent_age: 13 },
    { value: 'LT', label: t('country_lt', 'Lithuania'), order: 0, consent_age: 14 },
    { value: 'LU', label: t('country_lu', 'Luxembourg'), order: 0, consent_age: 16 },
    { value: 'LV', label: t('country_lv', 'Latvia'), order: 0, consent_age: 13 },
    { value: 'MA', label: t('country_ma', 'Morocco'), order: 0, consent_age: 13 },
    { value: 'MC', label: t('country_mc', 'Monaco'), order: 0, consent_age: 13 },
    { value: 'MD', label: t('country_md', 'Moldova, Republic of'), order: 0, consent_age: 13 },
    { value: 'ME', label: t('country_me', 'Montenegro'), order: 0, consent_age: 13 },
    { value: 'MG', label: t('country_mg', 'Madagascar'), order: 0, consent_age: 13 },
    { value: 'MK', label: t('country_mk', 'Macedonia (Republic of)'), order: 0, consent_age: 13 },
    { value: 'ML', label: t('country_ml', 'Mali'), order: 0, consent_age: 13 },
    { value: 'MO', label: t('country_mo', 'Macao'), order: 0, consent_age: 13 },
    { value: 'MQ', label: t('country_mq', 'Martinique'), order: 0, consent_age: 13 },
    { value: 'MR', label: t('country_mr', 'Mauritania'), order: 0, consent_age: 13 },
    { value: 'MS', label: t('country_ms', 'Montserrat'), order: 0, consent_age: 13 },
    { value: 'MT', label: t('country_mt', 'Malta'), order: 0, consent_age: 13 },
    { value: 'MU', label: t('country_mu', 'Mauritius'), order: 0, consent_age: 13 },
    { value: 'MW', label: t('country_mw', 'Malawi'), order: 0, consent_age: 13 },
    { value: 'MX', label: t('country_mx', 'Mexico'), order: 0, consent_age: 13 },
    { value: 'MY', label: t('country_my', 'Malaysia'), order: 0, consent_age: 13 },
    { value: 'MZ', label: t('country_mz', 'Mozambique'), order: 0, consent_age: 13 },
    { value: 'NA', label: t('country_na', 'Namibia'), order: 0, consent_age: 13 },
    { value: 'NE', label: t('country_ne', 'Niger'), order: 0, consent_age: 13 },
    { value: 'NG', label: t('country_ng', 'Nigeria'), order: 0, consent_age: 13 },
    { value: 'NI', label: t('country_ni', 'Nicaragua'), order: 0, consent_age: 13 },
    { value: 'NL', label: t('country_nl', 'Netherlands'), order: 0, consent_age: 16 },
    { value: 'NO', label: t('country_no', 'Norway'), order: 0, consent_age: 13 },
    { value: 'NZ', label: t('country_nz', 'New Zealand'), order: 0, consent_age: 13 },
    { value: 'OM', label: t('country_om', 'Oman'), order: 0, consent_age: 13 },
    { value: 'PA', label: t('country_pa', 'Panama'), order: 0, consent_age: 13 },
    { value: 'PE', label: t('country_pe', 'Peru'), order: 0, consent_age: 13 },
    { value: 'PH', label: t('country_ph', 'Philippines'), order: 0, consent_age: 13 },
    { value: 'PK', label: t('country_pk', 'Pakistan'), order: 0, consent_age: 13 },
    { value: 'PL', label: t('country_pl', 'Poland'), order: 0, consent_age: 16 },
    { value: 'PT', label: t('country_pt', 'Portugal'), order: 0, consent_age: 13 },
    { value: 'PY', label: t('country_py', 'Paraguay'), order: 0, consent_age: 13 },
    { value: 'QA', label: t('country_qa', 'Qatar'), order: 0, consent_age: 13 },
    { value: 'RO', label: t('country_ro', 'Romania'), order: 0, consent_age: 16 },
    { value: 'RS', label: t('country_rs', 'Serbia and Kosovo'), order: 0, consent_age: 13 },
    { value: 'RU', label: t('country_ru', 'Russian Federation'), order: 0, consent_age: 16 },
    { value: 'RW', label: t('country_rw', 'Rwanda'), order: 0, consent_age: 13 },
    { value: 'SA', label: t('country_sa', 'Saudi Arabia'), order: 0, consent_age: 13 },
    { value: 'SC', label: t('country_sc', 'Seychelles'), order: 0, consent_age: 13 },
    { value: 'SD', label: t('country_sd', 'Sudan'), order: 0, consent_age: 13 },
    { value: 'SE', label: t('country_se', 'Sweden'), order: 0, consent_age: 13 },
    { value: 'SG', label: t('country_sg', 'Singapore'), order: 0, consent_age: 13 },
    { value: 'SI', label: t('country_si', 'Slovenia'), order: 0, consent_age: 15 },
    { value: 'SK', label: t('country_sk', 'Slovakia (Slovak Republic)'), order: 0, consent_age: 16 },
    { value: 'SL', label: t('country_sl', 'Sierra Leone'), order: 0, consent_age: 13 },
    { value: 'SM', label: t('country_sm', 'San Marino'), order: 0, consent_age: 13 },
    { value: 'SN', label: t('country_sn', 'Senegal'), order: 0, consent_age: 13 },
    { value: 'SO', label: t('country_so', 'Somalia'), order: 0, consent_age: 13 },
    { value: 'SR', label: t('country_sr', 'Suriname'), order: 0, consent_age: 13 },
    { value: 'ST', label: t('country_st', 'Sao Tome and Principe'), order: 0, consent_age: 13 },
    { value: 'SV', label: t('country_sv', 'El Salvador'), order: 0, consent_age: 13 },
    { value: 'SX', label: t('country_sx', 'Sint Maarten'), order: 0, consent_age: 13 },
    { value: 'SZ', label: t('country_sz', 'Swaziland'), order: 0, consent_age: 13 },
    { value: 'TC', label: t('country_tc', 'Turks and Caicos Islands'), order: 0, consent_age: 13 },
    { value: 'TD', label: t('country_td', 'Chad'), order: 0, consent_age: 13 },
    { value: 'TG', label: t('country_tg', 'Togo'), order: 0, consent_age: 13 },
    { value: 'TH', label: t('country_th', 'Thailand'), order: 0, consent_age: 13 },
    { value: 'TJ', label: t('country_tj', 'Tajikistan'), order: 0, consent_age: 13 },
    { value: 'TM', label: t('country_tm', 'Turkmenistan'), order: 0, consent_age: 13 },
    { value: 'TN', label: t('country_tn', 'Tunisia'), order: 0, consent_age: 13 },
    { value: 'TR', label: t('country_tr', 'Turkey'), order: 0, consent_age: 13 },
    { value: 'TT', label: t('country_tt', 'Trinidad and Tobago'), order: 0, consent_age: 13 },
    { value: 'TW', label: t('country_tw', 'Taiwan'), order: 0, consent_age: 13 },
    { value: 'TZ', label: t('country_tz', 'Tanzania, United Republic of'), order: 0, consent_age: 13 },
    { value: 'UA', label: t('country_ua', 'Ukraine'), order: 0, consent_age: 13 },
    { value: 'UG', label: t('country_ug', 'Uganda'), order: 0, consent_age: 13 },
    { value: 'US', label: t('country_us', 'United States'), order: 2, consent_age: 13 },
    { value: 'UY', label: t('country_uy', 'Uruguay'), order: 0, consent_age: 13 },
    { value: 'UZ', label: t('country_uz', 'Uzbekistan'), order: 0, consent_age: 13 },
    { value: 'VA', label: t('country_va', 'Vatican City'), order: 0, consent_age: 13 },
    { value: 'VC', label: t('country_vc', 'Saint Vincent and the Grenadines'), order: 0, consent_age: 13 },
    { value: 'VE', label: t('country_ve', 'Venezuela'), order: 0, consent_age: 13 },
    { value: 'VG', label: t('country_vg', 'Virgin Islands (British)'), order: 0, consent_age: 13 },
    { value: 'VI', label: t('country_vi', 'Virgin Islands (U.S.)'), order: 0, consent_age: 13 },
    { value: 'YE', label: t('country_ye', 'Yemen'), order: 0, consent_age: 13 },
    { value: 'ZA', label: t('country_za', 'South Africa'), order: 0, consent_age: 13 },
    { value: 'ZM', label: t('country_zm', 'Zambia'), order: 0, consent_age: 13 },
    { value: 'ZW', label: t('country_zw', 'Zimbabwe'), order: 0, consent_age: 13 }
  ]
}
