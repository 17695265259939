import React, { useContext } from 'react'
import { createUser } from './UserCreationAPI'
import Cookies from 'universal-cookie'
import { screens } from './App'

import { LoadingContext } from '@pokemon/design.ui.loading-spinner'

import './RegistrationForm.css'
import { CreateUsernamePasswordPage } from '@pokemon/ptc-user.create-username-password-page'

export const RegistrationForm = (props) => {
  const { setLoading } = useContext(LoadingContext)

  return <CreateUsernamePasswordPage
      isChild={false}
      attemptUserCreation={async (username, password) => {
        try {
          const newValues = { ...props.userValues, username, password }
          await createUser(newValues)

          props.handleFormSubmit(newValues, () => {})
          props.changeScreen(screens.ACTIVATION_FORM_STATE)
          const cookies = new Cookies()
          cookies.remove('country')
          cookies.remove('dob')
          setLoading(false)

          return []
        } catch (e) {
          if (e.response) {
            // Pass through errors to form handler
            return e.response.data.errors
          }

          // Handle this ourselves
          console.error('unexpected error creating user')
          props.setError()
          return undefined
        }
      }}
      screenname={props.userValues.screen_name} />
}

export default RegistrationForm
